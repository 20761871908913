<template>
	<div id="team_user_tips">
		<el-dialog :title="pageConf.title" v-model="pageConf.show" destroy-on-close :width="1200">
			<ul class="user_tips-ul">
				<li v-for="(v,i) in taskInfo.list">
					<div class="user_tips-col">
						<div class="main-task">
							<span class="title_id">{{v.task_id}}</span>
							<span class="title_name">{{v.title}}</span>
							<span class="task_time">{{v.active_time}}</span>
						</div>
						<el-row class="user_tips-row" :gutter="24" v-for="(vv,ii) in v.taskList">
							<el-col :span="20">
								{{vv.title}}
							</el-col>
							<el-col class="list-content-right grey-text" :span="4">
								{{vv.use_time}}
							</el-col>
						</el-row>
					</div>
				</li>
			</ul>
			<template #footer>
				<div class="dialog-footer">
					<span class="footer-title">当前任务<span class="footer-num">{{taskInfo.sum.task}}</span></span>
					<span class="footer-title">当前tips<span class="footer-num">{{taskInfo.sum.tips}}</span></span>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive,
		onMounted,
		watch
	} from 'vue'
	export default defineComponent({
		name: 'team_user_tips',
		setup(props, context) {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			// 弹出层配置文件
			const pageConf = reactive({
				title: '',
				show: false,

				submit_btn_isload: false
			})

			// 成员任务列表
			const taskInfo = reactive({
				u_id: '',
				type: '',
				list: [],
				sum: {
					task: '',
					tips: ''
				}
			})

			// 显示页面
			const openPage = (u_id, title, type) => {
				// 显示页面
				pageConf.show = true

				// 存放弹窗标题
				if (type === 1) {
					pageConf.title = title + '-当前tips'
				} else if (type === 2) {
					pageConf.title = title + '-待处理tips'
				} else if (type === 3) {
					pageConf.title = title + '-已完成tips'
				}

				// 存放用户ID
				taskInfo.u_id = u_id

				// 存放查询类型
				taskInfo.type = type

				// 获取当前成员参与的任务信息
				getTaskList()
			}

			// 获取当前成员参与的任务信息
			const getTaskList = () => {
				// 执行请求操作
				ctx.$request_.post(ctx.$api_.state.Task.Team.tips.url, {
						u_id: taskInfo.u_id,
						type: taskInfo.type
					})
					.then((respon) => {
						if (respon.Code === 200) {
							// 加载子任务信息
							taskInfo.list = respon.Data.list
							taskInfo.sum = respon.Data.sum
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}

			return {
				pageConf,
				taskInfo,
				openPage
			}
		}
	})
</script>

<style lang="less">
	#team_user_tips {
		.user_tips-ul {
			list-style: none;
			padding: 0;
			max-height: 60vh;
			overflow-y: auto;

			.user_tips-col {
				margin-bottom: 4vh;

				.main-task {
					.title_id {
						display: inline-block;
						background-color: #409eff;
						color: #ffffff;
						text-align: center;
						min-width: 0.6vw;
						height: 0.6vw;
						line-height: 0.8vw;
						font-size: 0.6vw;
						padding: 0.2vw;
						border-radius: 0.1vw;
					}

					.title_name {
						height: 0.8vw;
						line-height: 0.8vw;
						margin-left: 0.3vw;
						font-size: 0.8vw;
						color: #333333;
					}

					.task_time {
						float: right;
						margin-right: 0.6vw;
						color: #999999;
					}
				}
			}

			.user_tips-row {
				background-color: #f7f8fa;
				margin: 1vh 0 0 0 !important;
				height: 4vw;
				line-height: 1.2vw;
				display: flex;
				align-items: center;
				color: #999999;

				.list-content-right {
					text-align: right;
				}
			}
		}

		.dialog-footer {
			text-align: left;

			.footer-title {
				color: #999999;
				font-size: 0.9vw;

				.footer-num {
					color: #409EFF;
					font-weight: 600;
					margin-left: 0.2vw;
					font-size: 1vw;
				}
			}

			.footer-title:last-child {
				margin-left: 1.5vw;
			}
		}
	}
</style>
