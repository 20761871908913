<template>
	<div id="task_team">
		<el-row>
			<el-col :span="4">
				<el-select v-model="tableInfo.conf.team_id" clearable filterable placeholder="全部团队"
					@change="searchGroupUser(1)">
					<el-option v-for="item in teamList.options" :label="item.title" :value="item.id">
					</el-option>
				</el-select>
			</el-col>
			<el-col :span="4">
				<el-select v-model="tableInfo.conf.duty_id" clearable filterable placeholder="全部岗位"
					@change="searchGroupUser(1)">
					<el-option v-for="item in dutyList.options" :label="item.title" :value="item.id">
					</el-option>
				</el-select>
			</el-col>
			<el-col :span="6" :offset="10">
				<el-input placeholder="请输入成员姓名、英文简称、手机号码" clearable v-model="tableInfo.conf.keywords"
					@keyup.enter="searchGroupUser(2)" @clear="searchGroupUser(2)">
					<template #append>
						<i class="iconfont icon-edit" @click="searchGroupUser(2)">&#xe6ab;</i>
					</template>
				</el-input>
			</el-col>
		</el-row>
		<div class="insert">
			<el-button size="medium" icon="el-icon-plus" type="primary" @click="openInsertPage"
				v-if="$pubFun_.verify_permiss($api_.state.Task.Team.add.token,$menu_.state.roleNode)">新建团队
			</el-button>
		</div>
		<div class="table">
			<CusTable ref="cusTable" title="团队管理" :datas="tableInfo.list" :cusConf="tableInfo.conf"
				:cusColums="tableInfo.columns" @editIcon="openEditPage" @chosePage="tableChosePage"
				@setPageSize="tablePageSize" @showHandleTips="showHandleTips" @showWaitTips="showWaitTips"
				@showSucTips="showSucTips" :ispaging="true"></CusTable>
		</div>
		<div class="dialog-page">
			<!-- 新建团队 start -->
			<el-dialog title="新建团队" v-model="pageConf.insert" destroy-on-close :width="560">
				<el-form :model="insertForm.datas" :rules="insertForm.rules" ref="formRef">
					<el-form-item>
						<el-select v-model="insertForm.datas.team_type" class="form-select" placeholder="请选择新建团队/成员类型"
							@change="choseTeamType">
							<el-option v-for="v in teamTypeList.options" :label="v.label" :value="v.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item prop="team_id" v-if="insertForm.datas.team_type === 1">
						<el-select v-model="insertForm.datas.team_id" class="form-select" placeholder="请选择所属团队">
							<el-option v-for="v in teamList.options" :label="v.title" :value="v.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item prop="user_list" v-if="insertForm.datas.team_type === 1">
						<el-select v-model="pageConf.select_check_uid" class="form-select" filterable remote
							reserve-keyword placeholder="请输入成员姓名、电话" no-data-text="无成员信息"
							:remote-method="searchUserInfo" :loading="pageConf.select_isload" @change="choseUserInfo">
							<el-option v-for="(v,i) in pageConf.search_user_list" :label="v.u_info" :value="i">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="insertForm.datas.team_type === 1 && pageConf.search_user_chose.length > 0">
						<div class="sub-title">成员信息</div>
						<div class="user-list">
							<el-row :gutter="24" class="user-row"
								v-for="(v,i) in $pubFun_.sortByKey(pageConf.search_user_chose,'is_headman')">
								<el-col :span="4">{{v.u_name}}</el-col>
								<el-col :span="5">{{v.u_tel1}}</el-col>
								<el-col :span="6">{{v.du_name}}</el-col>
								<el-col :span="3">{{v.is_headman ? "组长" : "组员"}}</el-col>
								<el-col :span="2">
									<i class="iconfont set_heademan" title="设置为组长" @click="setNewUserToHeadMan(i)"
										v-if="!v.is_headman">&#xe6ba;</i>
								</el-col>
								<el-col :span="2">
									<i class="iconfont del_user" title="删除成员" @click="delNewUser(i)">&#xe6aa;</i>
								</el-col>
							</el-row>
						</div>
					</el-form-item>
					<el-form-item prop="title" v-if="insertForm.datas.team_type === 2">
						<el-input v-model="insertForm.datas.title" class="form-input" placeholder="请输入团队名称">
						</el-input>
					</el-form-item>
				</el-form>
				<template #footer>
					<span class="dialog-footer" v-if="insertForm.datas.team_type > 0">
						<el-button type="primary" :loading="pageConf.submit_btn_isload" @click="insertSubmitForm()">确认新建
						</el-button>
						<el-button @click="pageConf.insert = false">取 消</el-button>
					</span>
				</template>
			</el-dialog>
			<!-- 新建团队 end -->
			<!-- 编辑团队信息 start -->
			<el-dialog title="编辑团队信息" v-model="pageConf.edit" destroy-on-close :width="560">
				<el-form :model="editForm.datas" :rules="editForm.rules" ref="formRef">
					<el-form-item prop="title">
						<el-input v-model="editForm.datas.title" class="form-input" placeholder="请输入团队名称">
						</el-input>
					</el-form-item>
					<el-form-item prop="user_list">
						<el-select v-model="pageConf.select_check_uid" class="form-select" filterable remote
							reserve-keyword placeholder="请输入成员姓名、电话" no-data-text="无成员信息"
							:remote-method="searchUserInfo" :loading="pageConf.select_isload" @change="choseUserInfo">
							<el-option v-for="(v,i) in pageConf.search_user_list" :label="v.u_info" :value="i">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="pageConf.search_user_chose.length > 0">
						<div class="sub-title">成员信息</div>
						<div class="user-list">
							<el-row :gutter="24" class="user-row"
								v-for="(v,i) in $pubFun_.sortByKey(pageConf.search_user_chose,'is_headman')">
								<el-col :span="4">{{v.u_name}}</el-col>
								<el-col :span="5">{{v.u_tel1}}</el-col>
								<el-col :span="6">{{v.du_name}}</el-col>
								<el-col :span="3">{{v.is_headman ? "组长" : "组员"}}</el-col>
								<el-col :span="2">
									<i class="iconfont set_heademan" title="设置为组长" @click="setNewUserToHeadMan(i)"
										v-if="!v.is_headman">&#xe6ba;</i>
								</el-col>
								<el-col :span="2">
									<i class="iconfont del_user" title="删除成员" @click="delNewUser(i)">&#xe6aa;</i>
								</el-col>
							</el-row>
						</div>
					</el-form-item>
				</el-form>
				<template #footer>
					<span class="dialog-footer">
						<el-button type="primary" :loading="pageConf.submit_btn_isload" @click="editSubmitForm()">确认编辑
						</el-button>
						<el-button @click="pageConf.edit = false">取 消</el-button>
					</span>
				</template>
			</el-dialog>
			<!-- 编辑团队信息 end -->
			<!-- tips情况 start -->
			<ShowUserTips ref="userTipsRef"></ShowUserTips>
			<!-- tips情况 end -->
		</div>
	</div>
</template>

<script type="text/javascript">
	import tableColumns from '@/assets/json/task/table/team-cloumns.json'
	import CusTable from '@/components/common/CusTable.vue'
	import ShowUserTips from '@/components/task/TaskToUserTips.vue'
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive
	} from 'vue'
	export default defineComponent({
		name: 'task_team',
		components: {
			CusTable,
			ShowUserTips
		},
		setup() {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			// 弹出层配置文件
			const pageConf = reactive({
				insert: false,
				edit: false,
				search_user_list: [],
				search_user_chose: [],
				select_check_uid: '',
				select_isload: false,
				submit_btn_isload: false
			})

			// 表单-挂载
			const formRef = ref()

			// 团队列表
			const teamList = reactive({
				options: []
			})

			// 获取团队信息
			const getTeam = () => {
				// 执行请求命令
				ctx.$request_nl_.post(ctx.$api_.state.Task.BaseInfo.team_info.url)
					.then((respon) => {
						if (respon.Code === 200) {
							teamList.options = respon.Data
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}

			// 岗位列表
			const dutyList = reactive({
				options: []
			})

			// 获取岗位信息
			const getDuty = () => {
				// 执行请求命令
				ctx.$request_nl_.post(ctx.$api_.state.Task.BaseInfo.duty_info.url)
					.then((respon) => {
						if (respon.Code === 200) {
							dutyList.options = respon.Data
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}

			// 顶部搜索栏
			const searchGroupUser = (type) => {
				if (type === 1) {
					//select筛选，清空关键字查询
					tableInfo.conf.keywords = ''
				} else if (type === 2) {
					//关键字查询，清空select筛选
					tableInfo.conf.team_id = ''
					tableInfo.conf.duty_id = ''
				}

				// 重置分页参数
				tableInfo.conf.curPage = 1

				// 执行搜索请求
				loadTeamList()
			}

			// 搜索成员信息
			const searchUserInfo = (keywords) => {
				// 重置已搜索的成员信息
				pageConf.search_user_list = []

				// 打开搜索成员加载状态
				pageConf.select_isload = true

				// 获取成员信息
				ctx.$request_nl_.post(ctx.$api_.state.Home.BaseInfo.search_info.url, {
						keywords: keywords
					})
					.then((respon) => {
						if (respon.Code === 200) {
							pageConf.search_user_list = respon.Data
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}

						// 关闭加载状态
						pageConf.select_isload = false
					})
			}

			// 选择成员信息
			const choseUserInfo = (index) => {
				// 设置默认状态为添加成功
				let opendUser = true

				// 循环已经添加的数据
				pageConf.search_user_chose.forEach((v, i) => {
					// 判断当前添加的成员是否已经添加
					if (v.u_id === pageConf.search_user_list[index].u_id) {
						opendUser = false
					}
				})

				if (opendUser === true) {
					// 添加成员
					pageConf.search_user_list[index].is_headman = false
					pageConf.search_user_chose.push(pageConf.search_user_list[index])
				} else {
					// 已存在，做出错误提示
					ctx.$notify({
						title: '温馨提示',
						message: '当前成员已经添加，请勿重复操作',
						type: 'warning'
					})
				}

				pageConf.select_check_uid = ''
			}

			// 设置成员为组长
			const setNewUserToHeadMan = (index) => {
				// 将当前选择的成员都设置为组员
				pageConf.search_user_chose.forEach((v, i) => {
					v.is_headman = false
				})

				// 将选择的成员设置为组长
				pageConf.search_user_chose[index].is_headman = true
			}

			// 删除成员
			const delNewUser = (index) => {
				pageConf.search_user_chose.splice(index, 1)
			}

			/*团队成员列表*/
			const tableInfo = reactive({
				list: [],
				columns: ctx.$pubFun_.verify_tableColumns(tableColumns.list, 'Team'),
				conf: {
					loadStatus: false,
					curPage: 1,
					pageLimit: 15,
					dataTotal: 0,
					sigleHide: true,
					keywords: '',
					team_id: '',
					duty_id: '',
					emptyText: ''
				}
			})

			// 获取团队成员列表
			const loadTeamList = () => {
				// 重置参数
				tableInfo.list = []
				tableInfo.conf.loadStatus = true

				// 执行请求操作
				ctx.$request_nl_.post(ctx.$api_.state.Task.Team.list.url, {
						page: tableInfo.conf.curPage,
						limit: tableInfo.conf.pageLimit,
						team_id: tableInfo.conf.team_id,
						duty_id: tableInfo.conf.duty_id,
						keywords: tableInfo.conf.keywords
					})
					.then((respon) => {
						if (respon.Code === 200) {
							// 循环存放团队数据
							tableInfo.list = respon.Data.datas
							tableInfo.conf.dataTotal = respon.Data.Count

							// 关闭加载状态
							tableInfo.conf.loadStatus = false
							tableInfo.conf.emptyText = '没有更多的数据了~'
						} else {
							// 关闭加载状态
							tableInfo.conf.loadStatus = false
							tableInfo.conf.emptyText = respon.Message
						}
					}).catch(error => {
						tableInfo.conf.loadStatus = false
						tableInfo.conf.emptyText = "服务器连接失败，请稍后重试"
					})
			}

			// 设置table当前页码变化
			const tableChosePage = (curPage) => {
				// 存放当前页面
				tableInfo.conf.curPage = curPage

				// 重新加载table数据
				loadTeamList()
			}

			// 设置table每页页码数
			const tablePageSize = (limit, page) => {
				// 存放页码数
				tableInfo.conf.pageLimit = limit

				// 判断查询当前页  *  条数 > 总条数不进行查询
				if (page === 1 || limit * page <= tableInfo.conf.dataTotal) {
					// 重新加载table数据
					loadTeamList()
				}
			}
			/*团队成员列表*/

			/*新建团队*/
			// 新建团队-新建团队/成员类型
			const teamTypeList = reactive({
				options: [{
					label: '新建成员',
					value: 1
				}, {
					label: '新建团队',
					value: 2
				}]
			})

			// 新建团队-选择新建团队/成员类型
			const choseTeamType = (val) => {
				// 重置关联板块选择
				insertForm.datas.user_list = []

				// 关闭按钮加载状态
				pageConf.submit_btn_isload = false

				// 重置配置信息
				pageConf.search_user_list = []
				pageConf.search_user_chose = []
				pageConf.select_check_uid = ''
			}

			// 新建团队-数据表单内容
			const insertForm = reactive({
				datas: {
					team_type: '',
					title: '',
					team_id: '',
					user_list: []
				},
				rules: {
					title: {
						required: true,
						message: '请输入团队名称',
						trigger: 'blur'
					},
					team_id: {
						required: true,
						message: '请选择所属团队',
						trigger: 'blur'
					},
					user_list: {
						required: true,
						message: '请选择成员',
						trigger: 'blur'
					}
				}
			})

			// 新建团队-打开新增页面
			const openInsertPage = () => {
				// 重置表单信息
				insertForm.datas.team_type = ''
				insertForm.datas.title = ''
				insertForm.datas.team_id = ''
				insertForm.datas.user_list = []

				// 重置配置信息
				pageConf.search_user_list = []
				pageConf.search_user_chose = []
				pageConf.select_check_uid = ''
				pageConf.insert = true
			}

			// 新建团队-提交团队/成员数据
			const insertSubmitForm = () => {
				if (insertForm.datas.team_type === 1) {
					// 清除默认值
					insertForm.datas.user_list = []

					// 循环已选择的团队成员信息存放进form表单
					pageConf.search_user_chose.forEach((v, i) => {
						insertForm.datas.user_list.push({
							u_id: parseInt(v.u_id),
							is_headman: v.is_headman
						})
					})
				}

				formRef.value.validate((valid) => {
					if (valid === true) {
						// 打开按钮加载状态
						pageConf.submit_btn_isload = true

						// 请求接口提交表单数据
						ctx.$request_nl_.post(ctx.$api_.state.Task.Team.add.url, insertForm
								.datas)
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									if (parseInt(insertForm.datas.team_type) === 1) {
										// 重新加载团队成员列表
										tableInfo.conf.curPage = 1
										loadTeamList()
									}

									// 重新加载团队、岗位列表信息
									getTeam()
									getDuty()

									// 重置配置信息
									pageConf.insert = false
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}

								// 关闭加载状态
								pageConf.submit_btn_isload = false
							}).catch(error => {
								// 关闭加载状态
								pageConf.submit_btn_isload = false
							})
					}
				})
			}
			/*新建团队*/

			/*编辑团队信息*/
			// 编辑团队信息-数据表单内容
			const editForm = reactive({
				datas: {
					title: '',
					team_id: '',
					user_list: []
				},
				rules: {
					title: {
						required: true,
						message: '请输入团队名称',
						trigger: 'blur'
					},
					user_list: {
						type: 'array',
						required: true,
						message: '请选择成员',
						trigger: 'blur'
					}
				}
			})

			// 编辑团队信息-打开编辑页面
			const openEditPage = (index) => {
				// 设置表单信息
				editForm.datas.team_id = tableInfo.list[index].team_id
				editForm.datas.title = tableInfo.list[index].team_name

				// 重置表单数据
				editForm.datas.user_list = []

				// 重置配置信息
				pageConf.search_user_list = []
				pageConf.search_user_chose = []
				pageConf.select_check_uid = ''
				pageConf.edit = true

				// 打开按钮加载状态
				pageConf.submit_btn_isload = true

				// 获取团队成员信息
				ctx.$request_nl_.post(ctx.$api_.state.Task.Team.list.url, {
						team_id: editForm.datas.team_id,
						page: 1,
						limit: tableInfo.conf.total
					})
					.then((respon) => {
						if (respon.Code === 200) {
							// 循环存放产品数据
							respon.Data.datas.forEach((v, i) => {
								pageConf.search_user_chose.push({
									u_id: v.u_id,
									u_name: v.u_name,
									u_tel1: v.u_tel1,
									du_name: v.du_name,
									is_headman: parseInt(v.is_headman) === 1 ?
										true : false
								})
							})
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}

						// 关闭加载状态
						pageConf.submit_btn_isload = false
					}).catch(error => {
						// 关闭加载状态
						pageConf.submit_btn_isload = false
					})
			}

			// 编辑团队信息-提交团队/成员数据
			const editSubmitForm = () => {
				// 清除默认值
				editForm.datas.user_list = []

				// 循环已选择的团队成员信息存放进form表单
				pageConf.search_user_chose.forEach((v, i) => {
					editForm.datas.user_list.push({
						u_id: parseInt(v.u_id),
						is_headman: v.is_headman
					})
				})

				formRef.value.validate((valid) => {
					if (valid === true) {
						// 打开按钮加载状态
						pageConf.submit_btn_isload = true

						// 请求接口提交表单数据
						ctx.$request_nl_.post(ctx.$api_.state.Task.Team.edit.url, editForm
								.datas)
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 重新加载团队、岗位列表信息
									getTeam()
									getDuty()

									// 重新加载团队成员信息列表
									loadTeamList()

									// 重置配置信息
									pageConf.edit = false
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}

								// 关闭加载状态
								pageConf.submit_btn_isload = false
							}).catch(error => {
								// 关闭加载状态
								pageConf.submit_btn_isload = false
							})
					}
				})
			}
			/*编辑团队信息*/
			
			// 团队成员tips挂载
			const userTipsRef = ref(null)
			
			// 打开用户任务情况界面
			const showHandleTips = (index) => {
				
				// 进行中tips
				userTipsRef.value.openPage(tableInfo.list[index].u_id,tableInfo.list[index].u_name,1)
			}
			const showWaitTips = (index) => {
				// 待处理tips
				userTipsRef.value.openPage(tableInfo.list[index].u_id,tableInfo.list[index].u_name,2)
			}
			const showSucTips = (index) => {
				// 已完成tips
				userTipsRef.value.openPage(tableInfo.list[index].u_id,tableInfo.list[index].u_name,3)
			}

			/*初次自动加载*/
			getTeam()
			getDuty()
			loadTeamList()
			/*初次自动加载*/

			return {
				pageConf,
				searchUserInfo,
				choseUserInfo,
				formRef,
				teamList,
				dutyList,
				searchGroupUser,
				tableInfo,
				tableChosePage,
				tablePageSize,
				teamTypeList,
				choseTeamType,
				setNewUserToHeadMan,
				delNewUser,
				insertForm,
				openInsertPage,
				insertSubmitForm,
				editForm,
				openEditPage,
				editSubmitForm,
				userTipsRef,
				showHandleTips,
				showWaitTips,
				showSucTips
			}
		}
	})
</script>

<style lang="less">
	#task_team {
		.insert {
			margin-top: 1vw;
		}

		.el-dialog {
			min-height: 15vw;

			.el-form {
				text-align: center;

				.el-form-item:last-child {
					margin-bottom: 0;
				}

				.form-select {
					width: 100%;
				}

				.form-input {
					width: 100%;
					margin-top: 1vw;
				}

				.sub-title {
					text-align: left;
				}

				.user-list {
					max-height: 16vw;
					overflow-y: auto;
					overflow-x: hidden;

					.user-row {
						background-color: #f5f6f7;

						.set_heademan,
						.del_user {
							cursor: pointer;
						}
					}

					.user-row:not(:first-child) {
						margin-top: 0.3vw;
					}
				}
			}
		}
	}
</style>
