<template>
	<div class="CusTable">
		<el-table :data="datas" style="width: 100%;" height="65vh" v-loading="cusConf.loadStatus"
			element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
			element-loading-text="获取数据中..." :empty-text="cusConf.emptyText ? cusConf.emptyText : tableConf.emptyText"
			@sort-change="tableSort" 
			:default-sort="{ prop: 'overtime_task_num', order: 'descending' }">
			<el-table-column :label="v.label" :width="v.width" :prop="v.prop" :sortable="v.sortable" :fixed="v.fixed"
				v-for="(v,i) in cusColums" :key="i" >
				<template #default="scope">
					<!-- 访问链接 -->
					<div v-if="v.type === 'link'">
						<el-link type="primary" target="_blank" :href="scope['row'][v.prop]" title="点击访问链接"
							v-if="scope['row'][v.prop] != null">{{scope['row'][v.prop]}}</el-link>
						<el-tooltip class="item" effect="dark" content="尚未设置,无法访问" placement="top" v-else>
							<span class="error-text">暂无信息</span>
						</el-tooltip>
					</div>
					<!-- 访问链接 -->
					<!-- checkbox开启/关闭 -->
					<div v-else-if="v.type === 'checkbox'">
						<el-switch :value="String(scope['row'][v.prop])" active-value="1" inactive-value="0"
							@change="setCheckbox(scope,v.prop,v.action)" :disabled="v.disabled"
							v-if="scope['row'][v.prop] != null">
						</el-switch>
						<el-tooltip class="item" effect="dark" content="无法设置,请联系开发人员" placement="top" v-else>
							<el-switch :value="false" disabled></el-switch>
						</el-tooltip>
					</div>
					<!-- checkbox开启/关闭 -->
					<!-- 按钮操作 -->
					<div v-else-if="v.type === 'button'">
						<el-button :type="bv.type" :size="bv.size ? bv.size : 'medium'" :round="bv.round"
							:class="bv.class" :disabled="bv.disabled" @click="clickBtn(scope,bv.action)"
							v-for="(bv,bi) in v.btns" :key="bi">{{bv.name}}</el-button>
					</div>
					<!-- 按钮操作 -->
					<!-- 图标操作 -->
					<div v-else-if="v.type === 'button_icon'">
						<i class="iconfont icon-edit" :title="iv.name" @click="clickBtnIcon(scope,iv.action)"
							v-for="(iv,ii) in v.btns_icon" :key="ii" v-html="iv.icon"></i>
					</div>
					<!-- 图标操作 -->
					<!-- 普通文本编辑 -->
					<div v-else-if="v.type === 'edit'">
						<span class="set-text" v-if="scope['row'][v.prop] != null">
							<span v-if="v.disabled === true">{{scope['row'][v.prop]}}</span>
							<span @click="setText(scope,v.prop,v.label,v.type,v.action)"
								v-else>{{scope['row'][v.prop]}}</span>
						</span>
						<el-tooltip class="item" effect="dark" content="无法设置,请联系开发人员" placement="top" v-else>
							<span class="error-text">暂无信息</span>
						</el-tooltip>
					</div>
					<!-- 普通文本编辑 -->
					<!-- 点击文本显示 -->
					<div v-else-if="v.type === 'click'">
						<span v-if="scope['row'][v.prop] != null">
							<span v-if="v.isSumVerify === true && parseInt(scope['row'][v.prop]) <= 0">
								{{scope['row'][v.prop]}}
							</span>
							<span class="table-click-text" :title="scope['row'][v.prop]" :style="{'color':v.color}"
								@click="clickText(scope,v.action,scope['row'])" v-else>{{scope['row'][v.prop]}}</span>
						</span>
						<el-tooltip class="item" effect="dark" content="未设置,请联系开发人员" placement="top" v-else>
							<span class="error-text">暂无信息</span>
						</el-tooltip>
					</div>
					<!-- 点击文本显示 -->
					<!-- 普通文本显示 -->
					<div v-else>
						<span
							:class="[v.class ? v.class_prefix + ' ' + v.class_prefix+'_' + scope['row'][v.class_prop] : '']"
							v-if="scope['row'][v.prop] != null" :style="{
								color:
								  v.cstcolor && scope['row'][v.cstcolor]
									? scope['row'][v.cstcolor]
									: v.color
									? v.color
									: '',
							  }">
							{{scope['row'][v.prop]}}
						</span>
						<el-tooltip class="item" effect="dark" content="未设置,请联系开发人员" placement="top" v-else>
							<span class="error-text">暂无信息</span>
						</el-tooltip>
					</div>
					<!-- 普通文本显示 -->
				</template>
			</el-table-column>
		</el-table>
		
		<el-pagination v-if="ispaging" class="pagination" background :current-page="cusConf.curPage" :page-size="cusConf.pageLimit"
			:page-sizes="cusConf.pageLimits ? cusConf.pageLimits : pageConf.pageLimits"
			:layout="cusConf.layout ? cusConf.layout: pageConf.layout" :total="cusConf.dataTotal"
			:disabled="cusConf.pageStatus" :hide-on-single-page="cusConf.sigleHide ? cusConf.sigleHide : false"
			@size-change="setPageSize" @current-change="chosePage">
		</el-pagination>
	</div>
</template>

<script>
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive
	} from 'vue'
	export default defineComponent({
		name: 'CusTable',
		props: ['title', 'datas', 'cusColums', 'cusConf', 'ispaging'],
		setup(props, context) {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			const tableConf = ref({
				emptyText: '没有更多的数据了~',
				export_index: 0
			})

			const pageConf = ref({
				pageLimits: [15, 50, 200, 500, 2000],
				layout: "prev, pager, next, total, jumper, sizes"
			})

			const tableSort = (column) => {
				/* 监听table排序 */
				
				context.emit('tableSort', column.prop, column.order === 'descending' ? 'desc' : (column.order === 'ascending' ? 'asc' : column.order ))
			}

			const setCheckbox = (list, col_name, action) => {
				/* checkbox状态变更 */

				context.emit(action, list.$index, col_name, 1 - parseInt(props.datas[list.$index][col_name]))
			}

			const setText = (list, col_name, label, type, action) => {
				/* input输入编辑 */

				context.$prompt('请输入' + label, '您正在修改' + label, {
					confirmButtonText: '保存',
					cancelButtonText: '放弃修改',
					showClose: false,
					closeOnClickModal: false,
					inputPattern: /\S/,
					inputValue: list['row'][col_name],
					inputType: type,
					inputErrorMessage: label + '格式不正确'
				}).then(({
					value
				}) => {
					context.emit(action, list.$index, col_name, value)
				}).catch(() => {
					ctx.$notify({
						title: '温馨提示',
						message: '您放弃了修改' + label,
						type: 'info'
					})
				})
			}

			const clickText = (list, action,row) => {
				/* 文本点击事件 */
				context.emit(action, list.$index,row)
			}

			const clickBtn = (list, action) => {
				/* 按钮点击事件 */

				context.emit(action, list.$index)
			}

			const clickBtnIcon = (list, action) => {
				/* 图标点击事件 */
				context.emit(action, list.$index)
			}

			const setPageSize = (num) => {
				/* 设置分页查询条数 */

				context.emit('setPageSize', num, props.cusConf.curPage)
			}

			const chosePage = (page) => {
				/* 获取当前查询页码数 */

				context.emit('chosePage', page)
			}

			return {
				tableConf,
				pageConf,
				tableSort,
				setCheckbox,
				setText,
				clickText,
				clickBtn,
				clickBtnIcon,
				setPageSize,
				chosePage
			}
		}
	})
</script>

<style lang="less">
	.CusTable {
		.line-text {
			text-decoration: none;
		}

		.set-text {
			cursor: pointer;
		}

		.error-text {
			color: #C0C4CC;
			cursor: pointer;
		}

		.pagination {
			margin-top: 0.5vw;
		}
	}
</style>
